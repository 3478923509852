import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/ryan.parr/Documents/Apps/sprinklr.design/src/templates/mdx-page.js";
import { Eg } from '../../../components/Docs/Foo.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3>{`positive situations`}</h3>
    <p>{`Each time our users experience a sense of accomplishment or fulfilment, we must acknowledge it through our content.`}</p>
    <ul>
      <li parentName="ul">{`Celebrate the action by starting with an interjection`}</li>
      <li parentName="ul">{`Write in second person and mention the reason for success`}</li>
      <li parentName="ul">{`Wherever possible, write about what this success means for the user`}</li>
    </ul>
    {/*
     Eg.
     Congratulations! Your Smart Response Model is active. Agents will now see the recommended responses.
     The intuition model is successfully active.
     */}
    <Eg pos="Congratulations! Your Smart Response Model is active. Agents will now see the recommended responses." neg="The intuition model is successfully active." mdxType="Eg" />
    <h3>{`negative situations`}</h3>
    <p>{`It is very important that we help our users particularly at times when they’re feeling hassled, lost or anxious.`}</p>
    <ul>
      <li parentName="ul">{`Empathise with our user’s problem and apologise if needed`}</li>
      <li parentName="ul">{`Write in first person and own the error or mistake by stating what went wrong`}</li>
      <li parentName="ul">{`Last, but not the least, do mention the next steps to resolve the issue`}</li>
    </ul>
    {/*
     Eg.
     Sorry, we could not update your message properties as your account appears disabled. To know more, view account settings.
     Ooops! Message properties could not be updated.
     */}
    <Eg pos="Sorry, we could not update your message properties as your account appears disabled. To know more, view account settings." neg="Ooops! Message properties could not be updated." mdxType="Eg" />
    <h3>{`neutral situations`}</h3>
    <p>{`Sometimes, the content within the platform may not address a particular emotion but is needed for informative purposes.`}</p>
    <ul>
      <li parentName="ul">{`Establish context and inform`}</li>
      <li parentName="ul">{`If needed, aid our user via content by providing supporting links and documents`}</li>
    </ul>
    {/*
     Eg.
     Due to recent updates to our privacy policy, effective immediately, clicking on the name of users in our platform will not redirect to their profiles. For other related questions, please visit our FAQ page.
     Bad: Effective Immediately, we will be disabling redirecting to a profile page upon clicking on the name of a person.
     */}
    <Eg pos="Due to recent updates to our privacy policy, effective immediately, clicking on the name of users in our platform will not redirect to their profiles. For other related questions, please visit our FAQ page." neg="Bad: Effective Immediately, we will be disabling redirecting to a profile page upon clicking on the name of a person." mdxType="Eg" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      